@import "../../assets/scss/_variables";

.table-data {
    &.ms-DetailsRow-cell {
        a {
            color: $color__black !important;
            text-decoration: none;
        }
    }
}

.ms-DetailsRow {
    &:hover {
        background: $color__gray--light;
        /*
        a, span {
            color: $color__red !important;
            font-weight: 700;

            &.span-red {
                color: $color__white !important;
            }
        }
        */
    }

    .span-red, .span-yellow, .span-green {
        padding: 5px 10px;
        border-radius: 11px;
    }

    .span-red {
        background: rgba(181, 31, 41, 0.5);
        color: $color__white !important;
    }

    .span-yellow {
        background: rgba(255, 255, 0, 0.5);
    }

    .span-green {
        background: rgba(0, 255, 0, 0.5);
    }
}
