
body {
    margin:0;
    font-size: 15px;
}

h1,h2,h3,h4,h5,h6 {
    line-height: 1;
    margin: 0;
    font-family: 'AvertaPE-Extrabold';
    text-decoration: none;
}


h1 {
    font-size: 40px;
}

h2 {
    font-size: 38px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 20px
}

h5 {
    font-size: 15px
}

a {
    text-decoration: none;
    color: #000
}