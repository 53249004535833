//Typo
$font__size--x-super-small: 12px;
$font__size--x-small: 14px;
$font__size--small: 15px;
$font__size--base: 22px;
$font__size--medium: 27px;
$font__size--large: 30px;
$font__size--h1: 48px;
$font__size--h2: 38px;
$font__size--h3: 28px;
$font__size--h4: 20px;

$line__height__small: 18px;
$line__height__base: 30px;
$line__height__medium: 40px;
$line__height__large: 48px;

$letter__spacing__medium: 0.4px;
$letter__spacing__large: 0.1px;

//Spaces
$space__default: 16px;

//Colors
$color__black: #000000;
$color__white: #ffffff;
$color__secondary: #00677f;
$color__gray--light: #f5f5f5;
$color__gray--dark: #444444;
$color__gray--medium: #c8c8c8;
$color__blue: #00adef;
$color__gold: #987147;
$color__red: #B51F29;

//Breakpoints
$x-large: 1366px;
$large: 1024px;
$medium: 768px;
$medium-small: 560px;
$small: 375px;
$x-small: 320px;