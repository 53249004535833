


/*


.container {
    max-width: 90% !important;
}

.main-content {
    min-height: calc(100vh - 447px);;
}

.ms-Button {
    &.is-checked {
        background-color: black;
        color: white;
    }
}


*/



@import '~office-ui-fabric-react/dist/sass/References.scss';

@font-face {
    font-family: 'AvertaPE';
      src:  url('./assets/fonts/AvertaPE-Regular.woff2') format('woff2'), 
            url('./assets/fonts/AvertaPE-Regular.woff') format('woff'), 
            url('./assets/fonts/AvertaPE-Regular.ttf')  format('truetype'); 
}

@font-face {
    font-family: 'AvertaPE-Extrabold';
      src:  url('./assets/fonts/AvertaPE-Extrabold.woff2') format('woff2'), 
            url('./assets/fonts/AvertaPE-Extrabold.woff') format('woff'), 
            url('./assets/fonts/AvertaPE-Extrabold.ttf')  format('truetype'); 
}

@font-face {
    font-family: 'AvertaPE-Light';
      src: url('./assets/fonts/AvertaPE-Light.woff2') format('woff2'),
           url('./assets/fonts/AvertaPE-Light.woff') format('woff'),
           url('./assets/fonts/AvertaPE-Light.ttf') format('truetype');
}


.activateFont {
    font-family: 'AvertaPE-Light';
}

.ms-Button--primary {
    text-transform: uppercase;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 14px;
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;
/*    min-width: 15.3125rem
*/}

.ms-Button--primary:hover,
.ms-Button--primary:checked {
    background-color: #987147;
    border: 1px solid #987147;
}